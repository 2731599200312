import request from '../../utils/request';



export const OperateFetchData = query => {
    return request({
        url: process.env.VUE_APP_URL+'LogAdmin/operate/find',
        method: 'get',
        params: query
    });
};


export const UserFetchData = query => {
    return request({
        url: process.env.VUE_APP_URL+'LogAdmin/user/find',
        method: 'get',
        params: query
    });
};

export const BonusFetchData = query => {
    return request({
        url: process.env.VUE_APP_URL+'LogAdmin/Bonus/find',
        method: 'get',
        params: query
    });
};




